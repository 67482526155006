<section>
  <div class="heading-row">
    <h2 class="d-inline"><i class="fa fa-file-text-o mr-2"></i>{{ pageTitle }}</h2>
    <div class="d-inline float-right text-right">
      <button class="btn btn-sm btn-white dropdown-toggle" [matMenuTriggerFor]="menu2">More</button>
      <mat-menu #menu2="matMenu" xPosition="before">
        <!--        <button mat-menu-item class="height-40" (click)="postFinalOrder('delivery')">Convert to Delivery</button>-->
        <button mat-menu-item class="height-40" (click)="goToOrder()">Go to Invoices</button>
      </mat-menu>
      <button mat-button class="btn btn-sm btn-secondary ml-1" (click)="goBackToInvoiceList()"><i
          class="fa fa-angle-left mr-1"></i><span class="back-btn"> Back</span></button>

    </div>
  </div>
  <div class="separator"></div>
  <form [formGroup]="orderFormGroup" class="order-form custom-mat square scrolly">
    <div class="card-header overview active-form">
      <div class="row">
        <div class="col-md-4">
          <label for="">Invoice Type</label>
          <div class="form-group smmatinput">
            <mat-form-field>
              <mat-label>Select Invoice Type</mat-label>
              <mat-select class="no-disabled-text" id="typeFormField" (ngModelChange)="orderTypeChanged($event)"
                required [(ngModel)]="selectedOrderTypeId" [ngModelOptions]="{ standalone: true }"
                [disabled]="isDeliveryForm">
                <mat-option *ngFor="let order of orderTypes" [value]="order.id">{{ order.name }}</mat-option>
                <hr class="orderHr">
                <mat-option id="typeBtn" class="in-opt-btn"><button (click)="addOrderType()"><i
                      class="os-icon os-icon-plus"></i>
                    Add Type</button></mat-option>
              </mat-select>
              <mat-error *ngIf="orderTypeFormControl.hasError('required')">Please select a type</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div [ngClass]="{ 'inactive-form': orderTypeFormControl.invalid }">
      <div class="card-body overview">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div class="form-group smmatinput" *ngIf="!isDepotOrder">
              <label for="">Customer </label>
              <p *ngIf="isDeliveryForm"><input type="text" class="form-control form-control-sm non-editable"
                  [value]="orderData.customer.customer_name" disabled></p>
              <div *ngIf="!isDeliveryForm">
                <mat-form-field class="example-full-width" id="customerFormField">
                  <input type="text" placeholder="Search Customer" (blur)="checkFormValidation()" matInput required
                    [formControl]="customerFormControl" [matAutocomplete]="auto" (keyup)="keyUp.next($event)">
                  <mat-autocomplete appOptionScroll (optionsScroll)="onScroll()"
                    (optionSelected)="getCustomerLobList($event.option.value)" #auto="matAutocomplete"
                    (optionActivated)="checkFormValidation()" [displayWith]="customerControlDisplayValue">
                    <mat-option *ngFor="let customer of filterCustomer" [value]="customer">
                      {{ customer.customer_code }} - {{ customer.name }}
                    </mat-option>
                    <mat-option *ngIf="isLoading">
                      <div class="select-placeholder-container">
                        <!-- <span>Loading...</span> -->
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                      </div>
                    </mat-option>
                    <hr class="customerHr">
                    <mat-option id="custBtn" class="in-opt-btn"><button (click)="addCustomer()"><i
                          class="os-icon os-icon-plus"></i> Add Customer</button></mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="customerFormControl.hasError('required')">Please select a customer</mat-error>
                </mat-form-field>
                <div class="row mt-3" *ngIf="creditLimit?.credit_limit_type && creditLimit?.credit_limit_type == 2">
                  <div class="col">
                    <div class="form-group row  m-0 p-0">
                      <label> Customer Lob:</label>
                      <div class="col-11 p-0">
                        <app-multiautocomplete isSingle="true" [options]="customerLobList"
                          [control]="customerLobFormControl" text="name" id="lob_id" placeholder="Select Lob"
                          objectName="lob" param1="name" param2="">
                        </app-multiautocomplete>
                        <mat-error *ngIf="customerLobFormControl.hasError('required')">Please select a Customer Lob
                        </mat-error>
                      </div>
                      <!-- <div class="col-1 p-2 mt-2">
                              <a (click)="openItemLob()" class="ml-2 settingIcon"><i
                                  class="os-icon os-icon-ui-46" aria-hidden="true"></i></a>
                          </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group smmatinput" *ngIf="isCustomerlobShow">
              <label for="">Warehouse</label>
              <div>
                <input type="text" class="form-control form-control-sm"
                  [value]="getStorageLocationName(orderData?.storageocation)" *ngIf="!showWareHs && isEditForm"
                  (focus)="openWarehouse()">
                <!-- <input type="text" class="form-control form-control-sm"
                  [value]="warehouseMappingDetails?warehouseMappingDetails:'Select Options'"
                  *ngIf="!showWareHs && !isEditForm" (focus)="openWarehouse()"> -->
                <app-multiautocomplete isSingle="true" [ngStyle]="{'display':showWareHs || !isEditForm?'block':'none' }"
                  [options]="storageLocationList" [control]="warehouseFormControl" text="name" id="id"
                  placeholder="Select Warehouse" objectName="customer-root-ddl" [param1]="'code'" [param2]="'name'">
                </app-multiautocomplete>
              </div>
            </div>
            <div class="form-group smmatinput" *ngIf="isDepotOrder">
              <label for="">Depot </label>
              <mat-form-field id="depotFormField">
                <mat-label>Select Depot</mat-label>
                <mat-select class="no-disabled-text" (blur)="checkFormValidation()" required
                  (ngModelChange)="depotChanged($event)" [formControl]="depotFormControl" [(ngModel)]="selectedDepotId"
                  [disabled]="isDeliveryForm || nextCommingNumberofInvoiceCode != ''">
                  <mat-option *ngFor="let depot of depots" [value]="depot.id">{{ depot.depot_name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="depotFormControl.hasError('required')">Please select a depot</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group smmatinput">
              <label for="">{{'Salesman'}}</label>
              <div>
                <!-- <mat-form-field id="salesmanFormField">
                  <mat-label>Select {{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}
                  </mat-label>
                  <mat-select class="no-disabled-text" (blur)="checkFormValidation()"
                    [formControl]="salesmanFormControl">
                    <mat-option *ngFor="let sman of salesmen" [value]="sman.user.id">
                      {{ sman.user ? sman.user.firstname + ' ' + sman.user.lastname : '' }} - {{ sman.salesman_code }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="salesmanFormControl.hasError('required')">Please select a salesman</mat-error>
                </mat-form-field> -->
                <app-multiautocomplete isSingle="true" [options]="salesmen" [control]="salesmanFormControl" text="user"
                  id="user_id" placeholder="Select Salesman" objectName="user" param1="firstname" param2="lastname">
                </app-multiautocomplete>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="">Invoice Number </label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-sm" (keypress)="restrictLength($event)"
                  [(ngModel)]="invoiceNumber" [ngModelOptions]="{standalone: true}" [disabled]="selectedOrderTypeId">
                <span class="setting-btn"><button (click)="openNumberSettings()"><i
                      class="os-icon os-icon-settings"></i></button></span>
              </div>
            </div>
            <div class="form-group">
              <label for="">Invoice Date </label>
              <input type="date" [min]="todayDate" class="form-control form-control-sm" required
                [formControl]="deliveryDateFormControl" id="deliveryDate">
            </div>
            <div class="form-group smmatinput">
              <label for="">Payment Terms </label>
              <mat-form-field>
                <mat-label>Select Payment Term</mat-label>
                <mat-select class="no-disabled-text" required id="termFormField"
                  (ngModelChange)="payTermChanged($event)" [(ngModel)]="selectedPaymentTermId"
                  [ngModelOptions]="{ standalone: true }" [disabled]="isDeliveryForm">
                  <mat-option *ngFor="let term of terms" [value]="term.id">{{ term.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentTermFormControl.hasError('required')">Please select a payment term</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label for="">Due Date </label>
              <input type="date" [min]="dueDateSet" [formControl]="dueDateFormControl" required
                class="form-control form-control-sm" id="dueDate">
            </div>
          </div>
        </div>

      </div>

      <div class="bottom-form">
        <div class="item-container custom-mat-no-border"
          [ngClass]="{ 'inactive-form': isDepotOrder ? depotFormControl.invalid : customerFormControl.invalid }">
          <table class="table-check">
            <thead>
              <tr>
                <th class="head">
                  <button class="btn btn-sm filter-btn" [matMenuTriggerFor]="menu" style="height: 30px;"><i
                      class="fa fa-filter"></i></button>
                  <mat-menu #menu="matMenu">
                    <div class="card-body p-2" style="min-width: 200px;">
                      <ul style="list-style:none; padding-left: 7px; margin-bottom: 0;">
                        <li *ngFor="let header of itemTableHeaders.slice(5, 9)">
                          <input type="checkbox" style="margin: 2px" [(ngModel)]="header.show"
                            (click)="$event.stopPropagation()" [ngModelOptions]="{ standalone: true }">
                          {{ header.label }}
                        </li>
                      </ul>
                      <div class="float-right mb-2">
                        <button mat-button class="btn mat-inherit-line-height filter-cancel-btn">Cancel</button>
                      </div>
                    </div>
                  </mat-menu>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="item-row" *ngFor="let item of payloadItems; let i = index;">
                <!--              <input class="item-checkbox" (change)="itemDidSelected($event, item)" type="checkbox" name="item">-->
              </tr>
            </tbody>
          </table>

          <div class="item-table-div ">
            <table class="item-table cardtable table">
              <thead>
                <tr>
                  <th class="head" *ngFor="let head of filteredTableHeaders">{{ head.label }}</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody class="form-body">
                <tr formArrayName="items" class="item-row" *ngFor="let item of itemFormControls; let i = index;">
                  <ng-container [formGroupName]="i">
                    <td class="item-sequence" data-th="#">
                      <span>{{ i + 1 }}</span>
                      <input (change)="itemDidSelected($event, payloadItems[i])" type="checkbox" name="item">
                    </td>
                    <td data-th="item Code" *ngIf="isDeliveryForm"><input type="text"
                        class="form-control form-control-sm non-editable" [value]="payloadItems[i]?.item.name" disabled>
                    </td>
                    <td *ngIf="!isDeliveryForm" class="item-name" data-th="item Code">
                      <mat-form-field class="item-name-field">
                        <input type="text" required placeholder="Search an item" matInput formControlName="item"
                          [matAutocomplete]="auto" (keyup)="keyUpItem.next($event)">
                        <mat-autocomplete appOptionScroll #auto="matAutocomplete" (opened)="checkFormValidation()"
                          (optionSelected)="itemDidSearched($event.option.value, i)"
                          [displayWith]="itemsControlDisplayValue">
                          <mat-option class="mat-option" *ngFor="let item of filteredItems"
                            [value]="itemControlValue(item)">
                            {{item.item_code}} - {{ item.item_name }} </mat-option>
                          <mat-option *ngIf="isLoading">
                            <div class="select-placeholder-container">
                              <!-- <span>Loading...</span> -->
                              <mat-spinner class="spinner" diameter="20"></mat-spinner>
                            </div>
                          </mat-option>
                          <hr class="customerHr">
                          <mat-option id="custBtn" class="in-opt-btn"><button (click)="redirectToItem()"><i
                                class="os-icon os-icon-plus"></i> Add Item</button></mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </td>
                    <td data-th="Item Name" *ngIf="isDeliveryForm"><input type="text"
                        class="form-control form-control-sm non-editable item-name" formControlName="item_name"
                        disabled></td>
                    <td data-th="Item Name" class="item-name" *ngIf="!isDeliveryForm">
                      <input type="text" class="form-control form-control-sm non-editable item-name"
                        formControlName="item_name" disabled>
                    </td>
                    <td class="uom-td" data-th="UOM" *ngIf="isDeliveryForm"><input type="text"
                        class="form-control form-control-sm non-editable" [value]="getUomValue(payloadItems[i])"
                        disabled></td>
                    <td class="uom-td" *ngIf="!isDeliveryForm" data-th="UOM">
                      <select class="custom-select" required formControlName="item_uom_id">
                        <option [value]="uom?.id" *ngFor="let uom of item.value.item_uom_list">{{ uom?.name }}</option>
                      </select>
                    </td>

                    <td [ngClass]="{'itemQtyAvlaible' : itemQtyAvlaible[item?.value?.item.id] == false}"
                      class="stats-cell" data-th="Quantity"><input type="number" min="0" class="form-control"
                        placeholder="Quantity" [readonly]="item?.value?.is_free" formControlName="item_qty"></td>
                    <td class="stats-cell" data-th="Price"><input type="text" class="form-control non-editable"
                        [value]="payloadItems[i]?.item_price" disabled></td>
                    <td data-th="Excise"><input type="text" class="form-control non-editable"
                        [value]="numberFormat(payloadItems[i]?.total_excise)" disabled>
                    </td>
                    <td class="stats-cell" data-th="Discount"><input type="text" class="form-control non-editable"
                        [value]="numberFormat(payloadItems[i]?.item_discount_amount)" disabled></td>
                    <td class="stats-cell" data-th="Net"><input type="text" class="form-control non-editable"
                        [value]="numberFormat(payloadItems[i]?.item_net)" disabled>
                    </td>
                    <td class="stats-cell" data-th="Vat"><input type="text" class="form-control non-editable"
                        [value]="numberFormat(payloadItems[i]?.item_vat)" disabled>
                    </td>
                    <td class="stats-cell" data-th="Total"><input type="text" class="form-control non-editable"
                        [value]="numberFormat(payloadItems[i]?.item_grand_total)" disabled></td>
                    <td data-th="Action"> <button [disabled]="(itemFormControls.length <= 1) || isDeliveryForm"
                        class="trash-btn" (click)="deleteItemRow(i)"><i class="fa fa-close"></i></button></td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
            <button mat-button class="mat-inherit-line-height add-item-btn" [disabled]="isDeliveryForm"
              (click)="addItem()">
              <!--            <i class="os-icon os-icon-plus"></i>-->
              Add Item
            </button>
            <button mat-button class="mat-inherit-line-height add-item-btn" style="margin-left: 5px;"
              (click)="openBulkItemSelectionPopup()">
              Add Bulk Item
            </button>
          </div>

          <!-- <table class="table-check">
            <thead>
            <tr><th class="head"></th></tr>
            </thead>
            <tbody>
            <tr class="item-row" *ngFor="let item of payloadItems; let i = index;">
              <button [disabled]="(itemFormControls.length <= 1) || isDeliveryForm" class="trash-btn" (click)="deleteItemRow(i)"><i class="fa fa-close"></i></button>
            </tr>
            </tbody>
          </table> -->
        </div>
        <div class=" footer-overview-container">
          <div class="row justify-content-between">
            <div class="form-group col-sm-5">
              <label for="note">Customer Note</label>
              <textarea class="form-control form-control-sm" [formControl]="noteFormControl" id="note"></textarea>
            </div>
            <div class="col-sm-4">
              <div class="overall-stats-table">
                <div
                  *ngFor="let stat of objectValues(orderFinalStats).slice(0, objectValues(orderFinalStats).length - 1)">
                  <span class="stat-label">{{ stat.label }}</span>
                  <span class="stat-value">{{ numberFormatWithSymbol(stat.value) }}</span>
                </div>
                <div>
                  <span class="stat-label final-total">{{ orderFinalStats.grand_total.label }}</span>
                  <span class="stat-value final-total">{{ numberFormatWithSymbol(orderFinalStats.grand_total.value)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="actions">
    <button class="btn btn-primary" (click)="postFinalInvoice('invoice')">Save & Submit</button>
  </div>
</section>