<div id="ember612" class="modal-header card-header ember-view">
    <p class="h4 font-weight-light d-inline">
        Pdf Read OCR
    </p>
    <button class="close no-outline" (click)="close()" type="button">
        &times;
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class=" card-body">

            <form [formGroup]="importForm">
                <div class="row">
                    <div class="form-group smmatinput col-lg-12">
                        <label for="">Select Customer</label>
                        <input type="text" class="example-full-width" matInput [matAutocomplete]="auto"
                            formControlName="customerFormControl" (keyup)="keyUp.next($event)">
                        <mat-autocomplete appOptionScroll (optionsScroll)="onScroll()" #auto="matAutocomplete"
                            [displayWith]="customerControlDisplayValue" required>
                            <mat-option *ngFor="let customer of filterCustomer" [value]="customer">
                                {{ customer.customer_code }} - {{ customer.name }} </mat-option>
                            <mat-option *ngIf="isLoading">
                                <div class="select-placeholder-container">
                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div>
                            </mat-option>
                            <!-- <mat-option id="custBtn" class="in-opt-btn"><button (click)="addCustomer()"><i
                                                class="os-icon os-icon-plus"></i> Add Customer</button></mat-option> -->
                        </mat-autocomplete>
                        <!-- <mat-error *ngIf="customerFormControl.hasError('required')">Please select a customer
                                </mat-error> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4"> <label>Upload Files : </label></div>
                    <div class="col-lg-8">
                        <input style="display: none" type="file" (change)="onSelectFile($event)"
                            formControlName="customer_file" name="customer_file" [(ngModel)]="importFile.customer_file"
                            #file required>
                        <button class="btn btn-sm btn-p" *ngIf='!(filesList.length > 0)' (click)="file.click()">Upload
                            file</button>
                        <span *ngIf='!(filesList.length > 0)'>No file selected</span>
                        <mat-chip-list #chipList aria-label="selection" *ngIf='(filesList.length > 0)'>
                            <mat-chip *ngFor="let file of filesList" [removable]="removable" (removed)="remove(file)">
                                {{file.name}}
                                <mat-icon matChipRemove *ngIf="removable">Cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                        <div class="font-style"> <small> Maximum File Size: 5 MB
                            </small>&nbsp;|&nbsp;
                            <small> File Format:
                                Pdf </small>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer card-footer">
    <button class="btn btn-primary ember-view" (click)="close();openForm()">
        Next</button>&nbsp;
    <button class=" btn btn-secondary" (click)="close()">Cancel</button>
</div>