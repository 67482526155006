<div class="pl-3 row mb-4 mt-4" *ngIf="loginData">
    <div class="col-md-1 col-form-label">Date</div>
    <div class="col-md-3 mb-md-0 mb-2">
      <input class="form-control form-control-sm" type="date" [formControl]="dateFilterControl">
    </div>
    <div class="col-md-3 item-add-btn">
      <button type="button" (click)="getLoginList('date', true)" class="mr-2 btn btn-sm btn-primary">Filter</button>
      <button type="button" (click)="getLoginList('all', true)" class="btn btn-sm btn-danger">All</button>
    </div>
  </div>
<div class="items-table-box pl-3 mt-2">
    <div class="table-responsive">
        <table mat-table [dataSource]="itemSource" class="mat-elevation-z8 table table-hover">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                    Date
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.date | date :"d MMM y h:mm a"}} </td>
            </ng-container>
            <ng-container matColumnDef="ipaddress">
                <th mat-header-cell *matHeaderCellDef>
                    IP Address
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.ip }} </td>
            </ng-container>
            <ng-container matColumnDef="browser">
                <th mat-header-cell *matHeaderCellDef>
                    Browser
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.browser }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [hidden]="itemSource.data.length < 5" pageSize="5" [pageSizeOptions]="[5,10]">
    </mat-paginator>
</div>