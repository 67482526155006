<section>
    <div class="table-box customerbox">
        <div class="heading-row clearfix">
            <p class="h4 font-weight-light d-inline">
                Master Data Download
            </p>
        </div>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">

            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="module">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <div class="row-action">
                        <i style="cursor: pointer;font-size: 20px;" class="fa fa-download" (click)="download(element
                        .module)" aria-hidden="true" click-stop-propagation></i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</section>